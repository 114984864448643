const Features = () => {

    return (
        <section id="features" className="features">
            <div className="container">

                <div className="section-title">
                    <h2>Neler Sunuyoruz ?</h2>
                    <p>Aracınızla ilgili beklenmedik durumlarla mı karşılaştınız. 
                        Sizin için hızlı ve uygun fiyatlı çözümler sunuyoruz. 
                        7 gün 24 saat hizmet veren Çağrı Merkezimiz ve 
                        tüm ülkede yaygın anlaşmalı hizmet birimleri ağımız ile 
                        Aracınızla ilgili başınıza gelebilecek her türlü sorunda 
                        Yol arkadaşınız yanınızda.</p>
                </div>

                <div className="row no-gutters">
                    <div className="col-xl-7 d-flex align-items-stretch order-2 order-lg-1">
                        <div className="content d-flex flex-column justify-content-center">
                            <div className="row">
                                <div className="col-md-6 icon-box" data-aos="fade-up">
                                    <i className='bx bxs-car-garage'></i>
                                    <h4>Çekme - Kurtarma</h4>
                                    <p>Türkiye'nin neresinde olursanız olun. Bir telefon kadar uzağınızdayız</p>
                                </div>
                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                                    <i className='bx bxs-car-crash'></i>
                                    <h4>Lastik Değişimi</h4>
                                    <p>Aksilikleri arkanızda bırakın siz sadece yolunuza odaklanın</p>
                                </div>
                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                                    <i className='bx bx-tachometer'></i>
                                    <h4>Yakıt Bitmesi</h4>
                                    <p>Seyahatlere hız kesmeden devam etmenizi sağlıyoruz</p>
                                </div>
                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300"> 
                                    <i className='bx bxs-car-mechanic'></i>
                                    <h4>Yerinde Onarım</h4>
                                    <p>Arıza durumunda hemen oradayız</p>
                                </div>
                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                                    <i className='bx bxs-key'></i>
                                    <h4>Oto Çilingir</h4>
                                    <p>Çilingir hizmeti</p>
                                </div>
                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                                    <i className='bx bx-transfer'></i>
                                    <h4>Seyahate Devam Hizmeti</h4>
                                    <p>Seyahatiniz yarım kalmasın. Sevdiklerinize çabucak ulaşın.</p>
                                </div>
                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                                    <i className='bx bx-hotel' ></i>
                                    <h4>Konaklama Hizmeti</h4>
                                    <p>Büyük çaplı arızalarda araç başında beklemeye son veriyoruz</p>
                                </div>
                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                                    <i className='bx bx-taxi'></i>
                                    <h4>İkame Araç Hizmeti</h4>
                                    <p>Kesintisiz yolunuza devam etmenizi sağlıyoruz</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                        <img src="assets/img/features.png" className="img-fluid" alt="" />
                    </div>
                </div>

            </div>
        </section>
    );
};
export default Features;