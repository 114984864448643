const Counts = () => {

    return (
        <section id="features" className="features">
            <div className="container">  
                <div className="row no-gutters">
                    <div className="col-xl-12  ">
                        <div className="content d-flex flex-column justify-content-center">
                            <div className="row">
                                <div className="col-md-4 icon-box" data-aos="fade-up">
                                    <i className='bi bi-emoji-smile'></i>
                                    <h2>390916</h2>
                                    <p> Mutlu Müşteri</p>
                                </div>
                                <div className="col-md-4 icon-box" data-aos="fade-up" data-aos-delay="400">
                                    <i className='bx bx-map'></i>
                                    <h2>5283</h2>
                                    <p>Hizmet Noktası</p>
                                </div>
                                <div className="col-md-4 icon-box" data-aos="fade-up" data-aos-delay="400">
                                    <i className='bx bxs-city' ></i>
                                    <h2>81</h2>
                                    <p>İlde Hizmet veriyoruz</p>
                                </div> 
                            </div>
                        </div>
                    </div> 
                </div>

            </div>
        </section>
    );
};
export default Counts;


