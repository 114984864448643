const Contact = () => {

    return (
        <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>İletişim</h2>
                    <p>Türkiye'nin her yerinde güvenle ve kesintisiz seyahat etmeniz için 7/24 buradayız</p>
                </div>

                <div className="row">

                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-lg-6 info">
                                <i className="bx bx-map"></i>
                                <h4>Adres</h4>
                                <p>Türkiye'nin,<br />81 İlinde</p>
                            </div>
                            <div className="col-lg-6 info">
                                <i className="bx bx-phone"></i>
                                <h4>7 /24 Çağrı Merkezi</h4>
                                <p>0 850 255 02 03<br /> </p>
                            </div>
                            <div className="col-lg-6 info">
                                <i className="bx bx-envelope"></i>
                                <h4>E Posta</h4>
                                <p>info@yoldakaldim.com.tr<br /></p>
                            </div>
                            <div className="col-lg-6 info">
                                <i className="bx bx-time-five"></i>
                                <h4>Çalışma Saatlerimiz</h4>
                                <p>7 Gün 24 Saat<br /></p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form" data-aos="fade-up"> */}
                            <div className="form-group">
                                <input placeholder="Adınız Soyadınız" type="text" name="name" className="form-control" id="name" required />
                            </div>
                            <div className="form-group mt-3">
                                <input placeholder="E Posta" type="email" className="form-control" name="email" id="email" required />
                            </div>
                            <div className="form-group mt-3">
                                <input placeholder="Başlık" type="text" className="form-control" name="subject" id="subject" required />
                            </div>
                            <div className="form-group mt-3">
                                <textarea placeholder="Mesajınız" className="form-control" name="message" rows="5" required></textarea>
                            </div>
                            <div className="my-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                {/* <div className="sent-message">Your message has been sent. Thank you!</div> */}
                            </div>
                            <div className="text-center">button</div>
                        {/* </form> */}
                    </div>

                </div>

            </div>
        </section>
    );
};
export default Contact;