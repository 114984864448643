import Logo from  '../image/yoldakaldim.svg'
const Header = () => {

    return (
        <div className="container d-flex align-items-center justify-content-between">

            <div className="logo">
                
                 <a href="index.html"><img src={Logo} alt="" class="img-fluid" width={25} /></a> <h3><a href="index.html">Yol Arkadaşım</a></h3>
            </div>

            <nav id="navbar" className="navbar">
                <ul>
                    <li><a className="nav-link scrollto active" href="#main">Anasayfa</a></li>
                    <li><a className="nav-link scrollto" href="#features">Hizmetlerimiz</a></li> 
                    <li><a className="nav-link scrollto" href="#packages">Paketlerimiz</a></li>  
                    <li><a className="nav-link scrollto" href="#contact">İletişim</a></li>
                    <li><a className="getstarted scrollto" href="#features">Destek</a></li>
                </ul>
                <i className="bi bi-list mobile-nav-toggle"></i>
            </nav>

        </div>
    );
};
export default Header;