// import logo from './logo.svg';
import './App.css';
import Contact from './components/contact';
import Features from './components/features';
import Footer from './components/footer';
import Header from './components/header';
// import Packages from './components/packages';
import Slider from './components/slider';
import Counts from './counts';

function App() {
  return (
    <>
    <Header />
    <Slider />
    <Features />
    {/* <Packages /> */}
    <Counts />
    <Contact />
    <Footer />
    </>
  );
}

export default App;
