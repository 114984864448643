const Slider = () => {

    return (
        <section id="hero" className="d-flex align-items-center">

            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1" data-aos="fade-up">
                        <div>
                            <h1>7/24 Canlı destek</h1>
                            <h2>Aracınız yolda kaldıysa dert etmeyin. 
                                Çekme, Kurtarma, Lastik Değişimi, Yakıt Bitmesi, 
                                Yerinde Onarım, İkame Araç Hizmeti ve daha fazlası burada.</h2>
                            {/* <a href="/#" className="download-btn"><i className="bx bxl-play-store"></i> Google Play</a>
                            <a href="/#" className="download-btn"><i className="bx bxl-apple"></i> App Store</a> */}
                            <h1>0 850 255 02 03</h1>
                        </div>
                    </div>
                    <div className="col-lg-6 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img" data-aos="fade-up">
                        <img src="assets/img/hero-img.png" className="img-fluid" alt="" />
                    </div>
                </div>
            </div>

        </section>
    );
};
export default Slider;