const Footer = () => {

    return (
        <footer id="footer">
            <div className="footer-newsletter">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <h4>Bültenimize kayıt olun</h4>
                            <p>Yeniliklerden haberdar olmak için bültenimize kayıt olun.</p>
                            {/* <form action="" method="post"> */}
                            <input type="email" name="email" /><input type="submit" value="Bülten e Katıl" />
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-6 footer-contact">
                            <h3>yoldakaldim.com.tr</h3>
                            <p />
                           Türkiye'nin <br />
                            81 İlinde Hizmet sunuyoruz<br /> <br />
                            <strong>Telefon:</strong> 0 850 255 02 03<br />
                            <strong>Email:</strong> info@yoldakaldim.com.tr<br />
                            < p />
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Hızlı Erişim</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <a href="/#">Anasayfa</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="/#">Hakkımızda</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="#features">Hizmetlerimiz</a></li> 
                                <li><i className="bx bx-chevron-right"></i> <a href="#contact">İletişim</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Hizmetlerimiz</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <a href="#features">Çekme - Kurtarma</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="#features">Lastik Değişimi</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="#features">Yakıt Bitmesi</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="#features">Yerinde Onarım</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="#features">ve Daha Fazlası...</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Sosyal Medya</h4>
                            <p>Bizi takip edin</p>
                            <div className="social-links mt-3">
                                <a href="/#" className="twitter"><i className="bx bxl-twitter"></i></a>
                                <a href="/#" className="facebook"><i className="bx bxl-facebook"></i></a>
                                <a href="/#" className="instagram"><i className="bx bxl-instagram"></i></a>
                                <a href="/#" className="google-plus"><i className="bx bxl-skype"></i></a>
                                <a href="/#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="container py-4">
                <div className="copyright">
                    &copy; Copyright <strong><span>yoldakaldim.com.tr</span></strong>. Bütün hakları saklıdır.
                </div>
                <div className="credits">
                    Designed by <a href="https://mobilinfo.com.tr/" target="blank">Mobilinfo.com.tr</a>
                </div>
            </div>
        </footer>
        
    );
};
export default Footer;